<template>
  <div class="container">
    <div class="head">
      <div class="search_box" style="margin-bottom: 20px;">
        <div class="search_main">
          <div class="status">
            <div>提现日期</div>
            <div>
              <el-date-picker
                v-model="datatime"
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd 23:59:59"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="search_main">
         
          <div class="status">
            <div>店铺名称</div>
            <div>
              <el-select style="width: 260px;margin-right: 10px;" v-model="queryinfo.storeName" filterable
                placeholder="请选择店铺" :filter-method="filterData" ref="searchSelect">
                <el-option value="" label="全部"></el-option>
                <el-option v-for="item in storeList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status">
            <div>提现单号</div>
            <div>
              <el-input
                v-model="queryinfo.cashOutNo"
                placeholder="请输入提现单号"
              ></el-input>
            </div>
          </div>
        </div>
        <div class="search_main">
          <div class="status1" style="display: flex">
            <div class="demandBtn" @click="search">
              <img src="../../assets/images/search.png" alt="" /> 查询
            </div>
            <div class="resetBtn" @click="reset">
              <img src="../../assets/images/reset.png" alt="" />重置
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-card class="body">

      <div class="text">
        <!-- 查询 -->
        <el-table :data="tableData" :header-cell-style="tableHeaderColor" :cell-style="{ color: '#2C2C2C', fontSize: '14px' }" style="width: 100%;font-family: PingFangRoutine;">
          <el-table-column prop="id" label="id" width="200"></el-table-column>
          <el-table-column prop="createTime" label="提现日期" width="200"></el-table-column>
          <el-table-column prop="finishTime" label="到账日期" width="200"></el-table-column>
          <el-table-column prop="freshStore.name" label="店铺名称" width="200"></el-table-column>
          <el-table-column prop="money" label="提现金额">
            <template slot-scope="scope">
               <span >{{ scope.row.money*0.01 }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="收款账户"></el-table-column>
          <el-table-column  label="提现状态">
            <template slot-scope="scope">
               <span v-show="scope.row.withdrawalStatus==0">待处理</span>
               <span v-show="scope.row.withdrawalStatus==1">提现成功</span>
               <span v-show="scope.row.withdrawalStatus==2">进行中</span>
               <span v-show="scope.row.withdrawalStatus==3">失败</span>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </el-card>
 <div style="height: 10px"></div>
      <div class="footer">
      <div class="Pagination">
        <div class="Pagination_amin">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="1"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
        <div class="Pagination_btn" style="height: 28px;">确定</div>
      </div>
       </div>
  </div>
</template>

<script>
import { orderRefundList } from "@/api/order";
import {cashOutList} from "@/api/finance"
import "../../font-style/font.css";
import {
  storeList
} from "@/api/store";
export default {
  data() {
    return {
      queryinfo: {
        pageNo: 1, //售后编号
       cashOutNo:null,//提现订单号
       pageSize:10,//每页显示数
       status:null,//提现状态
       storeName:null,//开始日期
       endDate:null,//结束日期
      },
      datatime: [], //时间
      total: null, //总条数
      activeName: "first", //tabs切换
      tableData: [], //表格数据
      memberId:'',
      storeList:[],
      storeQuery: {
        // 店铺列表请求参数
        currPage: 1,
        pageSize: 20,
        name: "",
      },
    };
  },
  created() {
    this.list();
    this.store()
  },
  methods: {
    tableHeaderColor({ rowIndex }) {
      if (rowIndex === 0) {
        return "background-color: rgba(246, 248, 249, 1);color: #333333;;fontSize: '14px'";
      }
    },
    // 售后列表
    list() {
      cashOutList(this.queryinfo).then(res=>{
        this.total = res.data.total * 1;
        this.tableData = res.data.records;
      })
    },
    // 查询
    search() {
      if (!this.datatime) { // 如果被清空，手动给它赋值为[]
        this.datatime = []
        this.queryinfo.startTime = ''
        this.queryinfo.endTime = ''
      }
      this.total=0
      this.queryinfo.startDate = this.datatime[0];
      this.queryinfo.endDate = this.datatime[1];
      this.list();
    },
      // 店铺列表
      store() {
      storeList(this.storeQuery).then((res) => {
        this.storeList = res.data.list;
      });
    },

    // 搜索框内容
    filterData(val) {
      this.storeQuery.name = val;
      this.store();
    },
    //重置
    reset() {
      this.total=0
      this.queryinfo.startDate=""
       this.queryinfo.endDate=""
       this.datatime=[]
      this.queryinfo.cashOutNo="",
      this.queryinfo.storeName=""
      this.list()
    },
    // 分页
    handleCurrentChange(val) {
      this.queryinfo.pageNo = val;
      this.list();
    },
    
  },
};
</script>

<style lang='less' scoped>
@import "../../assets/css/common.less";

.box-card {
  width: 100%;
}
.head{
  margin-bottom: 10px;
  background-color: white;
  padding-left: 20px;
  box-sizing: border-box;
  padding-top: 8px;
  padding-bottom: 0px;
  border-radius: 5px;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
}
/deep/ .tabs {
  margin-left: 20px;
  margin-top: 30px;
}
/deep/ .el-table th.el-table__cell > .cell {
  text-align: center;
}
/deep/ .el-table__cell {
  text-align: center;
}
/deep/ .el-table td.el-table__cell div{
  text-align: center !important;

}
</style>
